import * as React from "react";
import Container from "../components/comp-layouts/Container";
import Grid from "../components/comp-layouts/Grid";
import Aligner from "../components/comp-layouts/Aligner";
import Button from "../components/Button";
import * as Icon from "react-feather";
import Owl from "../components/comp-layouts/Owl";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import T from "../components/Text";
import ContactForm from "../components/forms/ContactForm.js";
import theme from "../theme/theme.json";
import ContactBanner from "../components/banners/ContactBanner";

const About = () => {
  return (
    <>
      <SEO
        title="Contact"
        description="Get in touch about our research, science communication, joining our team, media appearances, or any other general inquiries."
        slug="/contact"
      />
      <Layout>
        <ContactBanner />
        <Container mt="60px" mb="50px">
          <Owl mb="1.5rem">
            <h1>Contact</h1>
            <p>
              Get in touch about our research, science communication, joining
              our team, media appearances, or any other general inquiries.
            </p>
          </Owl>
          <Placer>
    <MailButton href="mailto:adriftlab@gmail.com">Email Us</MailButton>
    </Placer>
        </Container>
      </Layout>
    </>
  );
};

export default About;

const Placer = styled.div`
  display: flex;
`;

const MailButton = styled.a`
  padding: "13px";
  line-height: 1.25;
  font-weight:700;
  background-color: theme.col.primary.low;
  color: theme.col.bw.w;
  width:fit-content;
  transition: all 0.3s;
  & :hover {
    background-color: theme.col.primary.light;
    cursor: pointer;
  }
`;
