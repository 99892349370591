import React from "react";
import theme from "../../theme/theme.json";
import styled from "styled-components";

const ContactForm = () => {
  return (
    <StyledForm
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <FormGrid>
        <HalfField>
          <label htmlFor="name">Name*</label>
          <input type="text" name="name" id="name" required />
        </HalfField>
        <HalfField>
          <label htmlFor="org">Organisation</label>
          <input type="text" name="org" id="org" />
        </HalfField>
        <HalfField>
          <label htmlFor="email">Email Address*</label>
          <input type="text" name="email" id="email" required />
        </HalfField>
        <HalfField>
          <label htmlFor="phone">Phone Number</label>
          <input type="text" name="phone" id="phone" />
        </HalfField>
        <FullField>
          <label htmlFor="message">Messsage</label>
          <textarea name="message" id="message" rows="6" />
        </FullField>
        <FullField>
          <label htmlFor="attachment">Attachment</label>
          <input type="file" name="attachment" id="attachment" />
        </FullField>
        <QuarterField>
          <input type="submit" value="Send Message" />
        </QuarterField>
      </FormGrid>
    </StyledForm>
  );
};

export default ContactForm;

const StyledForm = styled.form`
  font-family: ${theme.ff.primary};
`;

const FormGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  & input[type="submit" i] {
    background-color: ${theme.col.primary.low};
    color: ${theme.col.bw.w};
    font-family: ${theme.ff.primary};
    font-weight: bold;
    border: none;
    padding: 10px;
    transition: all 0.3s;
  }
  & input[type="submit" i]:hover {
    background-color: ${theme.col.primary.light};
    cursor: pointer;
  }
  & label {
    font-family: ${theme.ff.primary};
    font-weight: bold;
    font-size: ${theme.fs.sm};
    padding-bottom: 0px;
  }
  & textarea,
  input[type="text" i] {
    font-family: ${theme.ff.primary};
    border: 1px solid #ccc;
    min-height: 30px;
    padding: 10px;
  }
`;

const FullField = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-column: span 4;
`;

const HalfField = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-column: span 4;
  @media (min-width: 768px) {
    grid-column: span 2;
  }
`;

const QuarterField = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-column: span 4;
  @media (min-width: 768px) {
    grid-column: span 1;
  }
`;
