import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const ContactBanner = ({ children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "silke-adriftlights-crop.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 2500
              placeholder: BLURRED
              quality: 90
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = placeholderImage.childImageSharp.gatsbyImageData;
  const pluginImage = getImage(image);

  return (
    <StyledBgImage
      style={{ backgroundPosition: "70% 72%" }}
      tag="section"
      image={pluginImage}
    >
      {children}
    </StyledBgImage>
  );
};

export default ContactBanner;

const StyledBgImage = styled((props) => <BgImage {...props} />)`
  height: 20vh;
  min-height: 200px;
  @media (min-width: 1024px) {
    height: 40vh;
    min-height: 450px;
  }
`;
